import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const url = process.env.VUE_APP_API_URL;
export default {
  namespaced: true,

  async getResutsByProvince(province_id) {
    try {
      const response = await axios.get(
        url + `getResultsByProvince/${province_id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getResutsByCountry() {
    try {
      const response = await axios.get(url + `getResutsByCountry`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getActas(province_id) {
    try {
      const response = await axios.get(
        url + `getResultsByActas/${province_id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getActasByProvince(data) {
    try {
      const response = await axios.get(
        url +
          `getActasByProvince?page=${data.page}&province_id=${data.province_id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getActasByTablePolling(data) {
    try {
      const response = await axios.get(
        url +
          `getActasByTablePolling?page=${data.page}&tablePolling_id=${data.tablePolling_id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getActasFromPollingStation(data) {
    try {
      const response = await axios.get(
        url +
          `getActasFromPollingStation?page=${data.page}&pollingStation_id=${data.pollingStation_id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getActasByDistrict(data) {
    try {
      const response = await axios.get(
        url +
          `getResultsByDistrictActas?page=${data.page}&district_id=${data.district_id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
};
