
<script setup>
import store from "@/store";
import feedVue from "./feed.vue";
import { ref, watch, computed, onMounted, render } from "vue";
import toastr from "toastr";
import PostStoreModule from "./PostStoreModule";
import { mapState, useStore, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import MyClientFooter from "../layouts/MyClientFooter.vue";
import authHeader from "@/Api/AuthHeader";
import bottomMenu from "@/components/bottomMenu.vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import axios from "axios";
import router from "@/router";
import Loading from "@/components/Loading.vue";
import indexedDBService from "@/LocalStorage/indexedDBService";

SwiperCore.use([Pagination, Navigation]);

const pollingStationData = ref({
  post_vote: store.state.auth.user.post_vote,
  post_vote_id: store.state.auth.user.post_vote_id,
  tablePolling_id: null,
  party_id_RENAMO: null,
  party_id_FRELIMO: null,
  party_id_MDM: null,
  party_id_PODEMOS: null,
  totalPolling_PODEMOS: null,
  totalPolling_MDM: null,
  totalPolling_RENAMO: null,
  totalPolling_FRELIMO: null,
  img: null,
});

const dbOffline = ref(null);

const publishing = ref(false);
// const store = useStore()

const page_type = ref(null);

const uploadImage = ref(
  "https://votes.vm7falaki.com/assets/images/uploadImg.svg"
);
const filesSvg = "https://votes.vm7falaki.com/assets/images/files.svg";
const btnImage = ref(
  "https://votes.vm7falaki.com/assets/images/justicehammer.svg"
);
const path = "https://votes.vm7falaki.com/Api/assets/upload_img/";
const ProfilePath =
  "https://votes.vm7falaki.com/starter/assets/upload_profile/";

// page_type.value = store.state.auth.user.page_type;
const url = process.env.VUE_APP_API_URL;

const loading = ref(false);
const showAlert = ref(false);
const imagemTempView = ref(null);
const VideoPreview = ref(null);
const docPreview = ref(null);
const DataParty = ref([]);
const DataTablePolling = ref([]);
const progress = ref(0);
const username = store.state.auth.user.username;
const ImgProfile = ref(store.state.auth.user.profile_pic);
const userId = store.state.auth.user.id;
const readAsDataURL = ref(null);
const photoUplaodDoc = ref(null);
const isWord = ref(false);
const ispfd = ref(false);

async function showVideos() {
  try {
    loading.value = true;

    const resp = await PostStoreModule.getVideoPosts();
    DataPost.value = resp.data.posts;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function showImages() {
  try {
    loading.value = true;

    const resp = await PostStoreModule.getImagesPosts();
    DataPost.value = resp.data.posts;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function showDocs() {
  try {
    loading.value = true;

    const resp = await PostStoreModule.getDocsPosts();
    DataPost.value = resp.data.posts;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
const status = ref("Esperando ação...");
async function submitData() {
  const reader = new FileReader();

  reader.onload = async () => {
    try {
      publishing.value = true;
      let data = new FormData();

      if (
        pollingStationData.value.tablePolling_id == null ||
        pollingStationData.value.party_id_PODEMOS == null ||
        pollingStationData.value.party_id_FRELIMO == null ||
        pollingStationData.value.party_id_MDM == null ||
        pollingStationData.value.party_id_RENAMO == null ||
        pollingStationData.value.totalPolling_FRELIMO == null ||
        pollingStationData.value.totalPolling_MDM == null ||
        pollingStationData.value.totalPolling_PODEMOS == null ||
        pollingStationData.value.totalPolling_RENAMO == null ||
        pollingStationData.value.img == null
      ) {
        toastr.warning("Por favor preencha um dos campos");
        publishing.value = false;
        return;
      }

      const parties = {
        PODEMOS: {
          party_id: pollingStationData.value.party_id_PODEMOS,
          totalPolling: pollingStationData.value.totalPolling_PODEMOS,
        },

        MDM: {
          party_id: pollingStationData.value.party_id_MDM,
          totalPolling: pollingStationData.value.totalPolling_MDM,
        },

        FRELIMO: {
          party_id: pollingStationData.value.party_id_FRELIMO,
          totalPolling: pollingStationData.value.totalPolling_FRELIMO,
        },

        RENAMO: {
          party_id: pollingStationData.value.party_id_RENAMO,
          totalPolling: pollingStationData.value.totalPolling_RENAMO,
        },
      };

      data.append("tablePolling_id", pollingStationData.value.tablePolling_id);
      data.append("img", pollingStationData.value.img);
      data.append("parties", JSON.stringify(parties));
      data.append("post_vote_id", pollingStationData.value.post_vote_id);
      data.append("province_id", store.state.auth.user.province_id);
      data.append("district_id", store.state.auth.user.district_id);
      // const res = await PostStoreModule.createPost(data);

      const imgBlob = new Blob([reader.result], {
        type: pollingStationData.value.img.type,
      });

      const dataParties = {
        tablePolling_id: pollingStationData.value.tablePolling_id,
        img: imgBlob,
        parties: parties,
        post_vote_id: pollingStationData.value.post_vote_id,
        province_id: store.state.auth.user.province_id,
        district_id: store.state.auth.user.district_id,
      };
      const config = {
        onUploadProgress: (progressEvent) => {
          progress.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      if (navigator.onLine) {
        syncData(data, config);
      } else {
        console.log("localFada", dataParties);
        indexedDBService
          .addData(dbOffline.value, dataParties)
          .then((response) => {
            toastr.success(response);
            status.value = "Dados armazenados localmente(offline)";
          })
          .catch((error) => {
            console.log(error);
            status.value = "Erro ao armazenar offline";
          });
      }
    } catch (error) {
      console.log(error);
    } finally {
      publishing.value = false;
    }
  };
  reader.readAsArrayBuffer(pollingStationData.value.img);
}

function handleScrollToBottom() {
  console.log("abc");
}

function clearField() {
  pollingStationData.value.tablePolling_id = null;
  pollingStationData.value.totalPolling_FRELIMO = null;
  pollingStationData.value.totalPolling_MDM = null;
  pollingStationData.value.totalPolling_RENAMO = null;
  pollingStationData.value.totalPolling_PODEMOS = null;
  pollingStationData.value.img = null;
  imagemTempView.value = null;
  docPreview.value = null;
  photoUplaodDoc.value = null;
}

function onChange(e) {
  docPreview.value = null;
  photoUplaodDoc.value = null;
  pollingStationData.value.img = e.target.files[0];
  const fileType = pollingStationData.value.img.type;

  if (fileType.includes("image")) {
    // if(postData.video.size > 5242880 ){
    //   toastr.info("O tamanho do video excede o limite permitido(5MB).")
    //   return;
    // }else{
    imagemTempView.value = URL.createObjectURL(pollingStationData.value.img);

    // }
  } else {
    toastr.info("Tipo de arquivo inválido");
  }
}

function onChangeVideo(e) {
  // console.log(e.target.files[0]);

  if (imagemTempView.value != null || docPreview.value != null) {
    postData.img = null;
    postData.doc = null;
    docPreview.value = null;
    imagemTempView.value = null;
    photoUplaodDoc.value.value = null;
  }
  postData.video = e.target.files[0];
  const fileType = postData.video.type;

  if (fileType.includes("video")) {
    // if(postData.video.size > 5242880 ){
    //   toastr.info("O tamanho do video excede o limite permitido(5MB).")
    //   return;
    // }else{
    VideoPreview.value = URL.createObjectURL(postData.video);

    // }

    console.log(postData.video.size);
  } else {
    toastr.info("Tipo de arquivo inválido");
  }
}

function onChangeDoc(e) {
  // console.log(e.target.files[0]);
  if (imagemTempView.value != null) {
    postData.img = "";
    imagemTempView.value = null;
    // toastr.info("Tipo de arquivo inválido")
  }

  if (VideoPreview.value != null) {
    postData.video = "";
    VideoPreview.value = null;
  }

  postData.doc = e.target.files[0];
  postData.docName = e.target.files[0].name;

  previewDocument();
  const fileType = postData.doc.type;
  console.log(e.target.files[0]);

  if (fileType.includes("pdf")) {
    ispfd.value = true;
    isWord.value = false;

    // if(postData.video.size > 5242880 ){
    //   toastr.info("O tamanho do video excede o limite permitido(5MB).")
    //   return;
    // }else{
    // docPreview.value = URL.createObjectURL(postData.doc);

    // }
    previewDocument();
  } else if (fileType.includes("word")) {
    ispfd.value = false;
    isWord.value = true;
  } else {
    toastr.info("Tipo de arquivo inválido");
  }
}

function previewDocument() {
  if (postData.doc) {
    const reader = new FileReader();

    reader.onload = (event) => {
      docPreview.value = event.target.result;
    };

    reader.readAsDataURL(postData.doc);
  }
}

async function getTablePolling() {
  try {
    loading.value = true;
    const res = await PostStoreModule.getTablePolling();
    DataTablePolling.value = res.data;
    console.log(res.data);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getParty() {
  const res = await PostStoreModule.getParty();
  DataParty.value = res.data;
  // setParty_id(DataParty.value);
  // pollingStationData.value.party_id_FRELIMO
}

const setParty_id = async (data) => {
  const res = await PostStoreModule.getParty();

  pollingStationData.value.party_id_FRELIMO = res.data.filter(
    (item) => item.description == "FRELIMO"
  )[0].id;
  pollingStationData.value.party_id_MDM = res.data.filter(
    (item) => item.description == "MDM"
  )[0].id;
  pollingStationData.value.party_id_PODEMOS = res.data.filter(
    (item) => item.description == "PODEMOS"
  )[0].id;
  pollingStationData.value.party_id_RENAMO = res.data.filter(
    (item) => item.description == "RENAMO"
  )[0].id;
  // console.log("id", resp);
};

watch(showAlert, (first, second) => {
  setTimeout(() => {
    showAlert.value = false;
  }, 5000);
});

//  ...mapActions('auth', ['likedPost']);

watch(
  () => store.state.auth.userlikedPost,
  () => {
    if (store.state.auth.userlikedPost == true) {
      getPosts();
      // getAllLikes();
      store.dispatch("auth/likedPost", false);
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

// Sincronizar dados quando online

const syncData = async (data, config) => {
  const localData = await indexedDBService.getAllData(dbOffline.value);
  let responser = null;

  if (localData.length > 0) {
    const formData = new FormData();
    // localData.forEach((item) => {
    //   formData.append("img", item.img);
    //   formData.append("parties", JSON.stringify(item.parties));
    //   formData.append("tablePolling_id", item.tablePolling_id);
    //   formData.append("post_vote_id", item.post_vote_id);
    //   formData.append("province_id", item.province_id);
    //   formData.append("district_id", item.district_id);
    // });
    for (let index = 0; index < localData.length; index++) {
      // = localData[index];
      formData.append("img", localData[index].img, "acta.png");
      formData.append("parties", JSON.stringify(localData[index].parties));
      formData.append("tablePolling_id", localData[index].tablePolling_id);
      formData.append("post_vote_id", localData[index].post_vote_id);
      formData.append("province_id", localData[index].province_id);
      formData.append("district_id", localData[index].district_id);
      responser = await axios.post(
        url + "post",
        formData,
        {
          headers: authHeader(),
          "content-type": "application/json",
        },
        config
      );
    }

    if (responser.data.status == true) {
      toastr.success(responser.data.message);
      await indexedDBService.clearData(dbOffline.value);
    }

    if (responser.data.status == false) {
      toastr.info(responser.data.message);
    }

    // limpar o Db local depois da sincronizacao
    clearField();
    setTimeout(() => {
      progress.value = 0;
    }, 3000);
  } else {
    responser = await axios.post(
      url + "post",
      data,
      {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      },
      config
    );

    if (responser.data.status == true) {
      toastr.success(responser.data.message);
      await indexedDBService.clearData(dbOffline.value);
    }

    if (responser.data.status == false) {
      toastr.info(responser.data.message);
    }

    // limpar o Db local depois da sincronizacao
    clearField();
    setTimeout(() => {
      progress.value = 0;
    }, 3000);
    // toastr.info("Nunhum dado para sincronizar");
  }
  // })
  // .catch((error) => {
  //   console.log("er", error);
  //   toastr.error("Error ao buscar dados locais");
  // });
};

onMounted(() => {
  if (store.state.auth.authenticated) {
    ImgProfile.value =
      store.state.auth.user.profile_pic == null
        ? "https://bitwise.bitwise.co.mz/assets/images/avatar4.png"
        : "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/" +
          store.state.auth.user.profile_pic;
  }

  page_type.value = store.state.auth.user.page_type;
  getParty();
  getTablePolling();
  setParty_id();

  console.log(navigator.onLine);

  indexedDBService.initDB().then((db) => {
    console.log("Db", db);
    // db.value = db;
    dbOffline.value = db;
  });

  window.addEventListener("online", syncData);
});
</script>

<template>
  <div class="container-fluid" v-if="page_type == 'A'">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Jassa</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end page-title -->
    <div class="row">
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-cube-outline bg-primary text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Active Sessions</h5>
            </div>
            <h3 class="mt-4">43,225</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">75%</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-briefcase-check bg-success text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total Revenue</h5>
            </div>
            <h3 class="mt-4">$73,265</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 88%"
                aria-valuenow="88"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">88%</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-tag-text-outline bg-warning text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Average Price</h5>
            </div>
            <h3 class="mt-4">447</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                style="width: 68%"
                aria-valuenow="68"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">68%</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-buffer bg-danger text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Add to Card</h5>
            </div>
            <h3 class="mt-4">86%</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                style="width: 82%"
                aria-valuenow="82"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">82%</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
    <div class="row">
      <div class="col-xl-4">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Recent Activity</h4>
            <ol class="activity-feed mb-0">
              <li class="feed-item">
                <div class="feed-item-list">
                  <p class="text-muted mb-1">Now</p>
                  <p class="font-15 mt-0 mb-0">
                    Jassa magna Jassa, risus posted a new article:
                    <b class="text-primary">Forget UX Rowland</b>
                  </p>
                </div>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">Yesterday</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa posted a new article:
                  <b class="text-primary">Designer Alex</b>
                </p>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">2:30PM</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa, Jassa, Jassa Commented
                  <b class="text-primary"> Developer Moreno</b>
                </p>
              </li>
              <li class="feed-item pb-1">
                <p class="text-muted mb-1">12:48 PM</p>
                <p class="font-15 mt-0 mb-2">
                  Jassa, Jassa Commented <b class="text-primary">UX Murphy</b>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-md-9 col-sm-8">
      <div
        class="card col-lg-12 col-sm-12"
        style="margin-left: -2em"
        v-if="page_type == 'M' || page_type == 'V'"
      >
        <div class="card-body">
          <div>
            <h1
              class="text-center mb-5"
              style="font-size: 18px; font-family: sans-serif"
            >
              Registo de Votos
            </h1>
          </div>
          <form @submit.prevent="submitData">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Posto de votação*</label>

                  <input
                    type="text"
                    class="form-control"
                    v-model="pollingStationData.post_vote"
                    disabled
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Mesa de voto*</label>
                  <select
                    class="form-control"
                    v-model="pollingStationData.tablePolling_id"
                  >
                    <option value="0">Selecione mesa de voto</option>
                    <option
                      v-for="(t, index) in DataTablePolling"
                      :value="t.id"
                      :key="index"
                    >
                      {{ t.description }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" v-for="(p, index) in DataParty" :key="index">
              <div
                class="col-lg-6 col-md-6 col-sm-12"
                v-if="p.description == 'PODEMOS'"
              >
                <div class="form-group">
                  <label class="form-label">Partido*</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="p.description"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-md-12 col-sm-12"
                v-if="p.description == 'PODEMOS'"
              >
                <div class="form-group">
                  <label class="form-label">Total de votos*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="pollingStationData.totalPolling_PODEMOS"
                  />
                </div>
              </div>
            </div>

            <div class="row" v-for="(p, index) in DataParty" :key="index">
              <div
                class="col-lg-6 col-md-6 col-sm-12"
                v-if="p.description == 'FRELIMO'"
              >
                <div class="form-group">
                  <label class="form-label">Partido*</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="p.description"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-md-12 col-sm-12"
                v-if="p.description == 'FRELIMO'"
              >
                <div class="form-group">
                  <label class="form-label">Total de votos*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="pollingStationData.totalPolling_FRELIMO"
                  />
                </div>
              </div>
            </div>

            <div class="row" v-for="(p, index) in DataParty" :key="index">
              <div
                class="col-lg-6 col-md-6 col-sm-12"
                v-if="p.description == 'MDM'"
              >
                <div class="form-group">
                  <label class="form-label">Partido*</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="p.description"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-md-12 col-sm-12"
                v-if="p.description == 'MDM'"
              >
                <div class="form-group">
                  <label class="form-label">Total de votos*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="pollingStationData.totalPolling_MDM"
                  />
                </div>
              </div>
            </div>

            <div class="row" v-for="(p, index) in DataParty" :key="index">
              <div
                class="col-lg-6 col-md-6 col-sm-12"
                v-if="p.description == 'RENAMO'"
              >
                <div class="form-group">
                  <label class="form-label">Partido*</label>

                  <input
                    type="text"
                    class="form-control"
                    :value="p.description"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-md-12 col-sm-12"
                v-if="p.description == 'RENAMO'"
              >
                <div class="form-group">
                  <label class="form-label">Total de votos*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="pollingStationData.totalPolling_RENAMO"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group">
                <label for="photoUpload">
                  <span id="foto"
                    ><img
                      :src="uploadImage"
                      width="25"
                      height="25"
                      alt=""
                      style="margin-left: 5px; margin-right: 5px"
                    />
                  </span>

                  <input
                    type="file"
                    id="photoUpload"
                    @change="onChange"
                    hidden
                  />
                </label>
              </div>
            </div>

            <!-- Image Preview -->
            <div class="user-block mb-1" v-if="imagemTempView != null">
              <img :src="imagemTempView" alt="" class="img-bordered-sm" />
            </div>

            <!-- {{ postData.img}} -->
            <!-- End -->
            <div class="modal-footer col-lg-12 col-md-12 col-sm-12">
              <button type="submit" class="btn btn-danger float-right">
                {{ publishing == false ? "Salvar" : "Salvando..." }}
                <!-- <img :src="btnImage" width="20" height="20" alt="" /> -->
              </button>
            </div>
            <div v-if="progress > 0">Carregando {{ progress }}%</div>
          </form>
        </div>
        <div class="row"></div>
      </div>

      <!-- <feedVue :dataPost="DataPost" :Datalikes="DataPostLike" /> -->
      <div v-observe-visibility="handleScrollToBottom"></div>
    </div>

    <div class="col-lg-3 col-xxl-3 col-lg-3 mt-2 float-right">
      <!-- Profile Image -->

      <div
        class="row d-none d-lg-block d-sm-none"
        style="height: 200px; margin-right: -3.5em"
      >
        <div class="col-lg-12 col-sm-8">
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <router-link :to="{ name: 'profile', params: { id: userId } }">
                <div class="text-center">
                  <img
                    :src="ImgProfile"
                    class="profile-user-img img-circle img-fluid"
                  />
                </div>
                <h3 class="profile-username text-center">
                  {{ username }}
                </h3>
              </router-link>
              <!-- <p class="text-muted text-center"> Engineer</p>  -->
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>

      <div class="row" style="margin-right: -3.5em">
        <div class="col-lg-12 col-sm-8">
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <li><a href="#">Sobre</a></li>
              <li><a href="#">Termos e Privancidade</a></li>
              <li><a href="#">Dúvidas?</a></li>
              <li><a href="#">Ajuda?</a></li>
            </div>
            <span class="text-white text-center">
              Esse pais e nosso salve mocambique &copy; 2024
            </span>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
      <!-- /.card -->
    </div>

    <bottomMenu />
  </div>
  <Loading :loadingData="loading" />
</template>

<style scoped>
#foto {
  background: #2e304a;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 12px 4px 0px rgba(0, 0, 0, 0.1);
}

#foto:hover {
  background: #f5f1f1;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #27538d;
}

#foto {
  margin-left: 15px;
  padding: 10px 10px;
  border-radius: 8px;
}

.user-block {
  float: left;
}
.user-block img {
  float: left;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

#postCheck {
  text-decoration: underline;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide.teste .test {
  position: absolute;
  top: 200px;
  left: 10em;
  width: 50%;
  height: 200px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.img-circle {
  border-radius: 50%;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

.footer-custom li {
  display: block;
  margin-top: 5px;
  margin-left: 6px;
}

#BottomMenu {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 3rem;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
}
</style>
