import { createApp } from "vue";
// import Home_main from './components/home_main.vue'
import AppMain from "./AppMain.vue";
import Router from "@/router";
import store from "@/store";
import toastr from "toastr";
import axios from "axios";
import VueAxios from "vue-axios";
// import { VueSelect } from "vue-select/dist/vue-select.es";
import "vue-select/dist/vue-select.css";
import LaravelVuepagination from "laravel-vue-pagination";
import { Bootstrap4Pagination } from "laravel-vue-pagination";
import VueLazyload from "vue-lazyload";
import VueObserveVisibility from "vue-observe-visibility";

axios.defaults.withCredentials = true;

// const app = createApp({});
// // app.component(Home_main)
// app.mount("#app")
const loadimage = require("../public/assets/images/loader.gif");

const app = createApp(AppMain);
// app.component("v-select", VueSelect)
app.use(VueAxios, axios);
app.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadimage,
  attempt: 1,
});
app.use(VueObserveVisibility);
app.use(Router);
app.use(toastr);
app.use(store);
app.use(Bootstrap4Pagination);
app.mount("#app");
