import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const url = process.env.VUE_APP_API_URL;
export default {
  namespaced: true,

  async createPost(FormData) {
    try {
      const response = await axios.post(url + "post", FormData, {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getProvinces() {
    try {
      const response = await axios.get(url + "provinces");
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getVideoPosts() {
    return await axios.get(url + "getVideoPosts", {
      headers: authHeader(),
    });
  },
  async getImagesPosts() {
    return await axios.get(url + "getImagesPosts", {
      headers: authHeader(),
    });
  },
  async getDocsPosts() {
    return await axios.get(url + "getDocsPosts", {
      headers: authHeader(),
    });
  },
  async getPosts() {
    try {
      const res = await axios.get(url + "getPosts", {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async getPostbyId(id) {
    try {
      const res = await axios.get(url + "getPosts/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async getLikesByPostbyId(id) {
    try {
      const res = await axios.get(url + "likesByPost/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async createLike(id_post) {
    try {
      const response = await axios.get(url + "createLike/" + id_post, {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getDislikesByPostbyId(id) {
    try {
      const res = await axios.get(url + "dislikesByPost/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async createDislike(id_post) {
    try {
      const response = await axios.get(url + "createDislike/" + id_post, {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getAllLikes() {
    try {
      const response = await axios.get(url + "allLikes", {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getParty() {
    try {
      const response = await axios.get(url + "getParty", {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getTablePolling() {
    try {
      const response = await axios.get(url + "getTablePolling", {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};
