<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="card col-lg-8 mx-auto" style="margin-top: 15em">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <!-- <div class="text-center mb-5 mt-4">
                      <img src="assets/images/logo.jpeg" alt="" />
                    </div> -->
                    <h2
                      class="text-center mb-5"
                      style="text-transform: uppercase"
                    >
                      Jovens Unidos
                    </h2>
                    <form action="index.html">
                      <div class="form-group">
                        <label><strong>E-mail</strong></label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.email"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          type="button"
                          class="btn-block"
                          style="
                            background-color: #e99239;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-on:click="handleSubmitButton"
                        >
                          {{ loading == false ? "Enviar" : "Enviando..." }}
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p class="text-center">
                        Voltar para tela de login
                        <router-link style="color: #0093dd" to="/login"
                          >clique aqui</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from "toastr";
import axios from "axios";
export default {
  data() {
    return {
      data: {
        // email: 'admin@ispocel.ac.mz',
        email: "",
      },
      loading: false,
      emailError: "",
      passwordError: "",
    };
  },

  methods: {
    async handleSubmitButton() {
      try {
        const url = process.env.VUE_APP_API_URL;
        this.loading = true;
        console.log("egsds");

        if (this.data.email == "") {
          this.loading = false;

          toastr.warning("O campo email não pode estar vazio!");
          return;
        }

        await axios
          .post(url + "recover_password", this.data)
          .then(({ data }) => {
            console.log(data.status);

            if (data.status == false) {
              toastr.error(
                "Nao foi possivel enviar um link no seu e-mail, tente mais tarde"
              );
            } else {
              toastr.success(
                "Por favor verifique a sua caixa de mensagem no seu email, enviamos um link recuperacao!"
              );
            }
          })
          .catch((response) => {
            console.log(response);
            toastr.error(
              "Erro de tantar recuperar o email ou contacte o administrador" +
                response.data.message
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
