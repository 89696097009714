export default {
  initDB() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open("myAppDB", 3);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore("offlineData", {
          keyPath: "id",
          autoIncrement: true,
        });
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject("Erro ao abrir IndexedDB: " + event.target.errorCode);
      };
    });
  },

  //   adiciona dados oa indexedDb
  addData(db, data) {
    console.log("teste", db);
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["offlineData"], "readwrite");
      const store = transaction.objectStore("offlineData");
      store.add(data);

      transaction.oncomplete = () => {
        resolve("Dados salvos localmente");
      };

      transaction.onerror = () => {
        reject("Erro ao salvar dados");
      };
    });
  },

  getAllData(db) {
    console.log(db);
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["offlineData"], "readonly");
      const store = transaction.objectStore("offlineData");
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = () => {
        reject("Error ao buscar dados");
      };
    });
  },

  clearData(db) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["offlineData"], "readwrite");
      const store = transaction.objectStore("offlineData");
      store.clear();

      transaction.oncomplete = () => {
        resolve("Dados locais removidos");
      };

      transaction.onerror = () => [reject("erro ao limpar")];
    });
  },
};
