<template>
  <div class="page-title-box">
    <!-- <div class="row align-items-center">
        
        <div class="col-sm-6">
          <div class="page-title">
            <h4>Perfil</h4>
          </div>
        </div>
         
    </div> -->
    <!-- End row -->
  </div>
  <div class="row">
    <div class="col-xl-9 col-xxl-9 col-lg-9">
      <div class="card">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    href="#my-posts"
                    data-toggle="tab"
                    class="nav-link active show"
                    >Meus posts</a
                  >
                </li>

                <li class="nav-item">
                  <a href="#my-config" data-toggle="tab" class="nav-link"
                    >Actualizar
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div id="my-posts" class="tab-pane fade active show">
                  <div class="profile-personal-info">
                    <div
                      class="post pt-5"
                      v-for="post in dataPosts"
                      :key="post.id"
                    >
                      <div class="user-block">
                        <img
                          v-if="post.user.profile_pic == null"
                          :src="imgUrl"
                          alt=""
                          class="img-circle img-bordered-sm"
                        />
                        <img
                          v-else
                          :src="ProfilePath + post.user.profile_pic"
                          alt=""
                          class="img-circle img-bordered-sm"
                        />
                        <span class="username">
                          <router-link
                            :to="{
                              name: 'profile',
                              params: { id: post.users_id },
                            }"
                            >{{ post.user.username }}</router-link
                          >
                          <!-- <a href="" class="float-right btn-tool"><i class="fas fa-times"></i></a> -->
                        </span>
                        <span class="description">
                          Criado - {{ formatDates(post.created_at) }}
                        </span>
                      </div>
                      <p v-if="post.description">
                        <router-link
                          :to="{ name: 'comments', params: { id: post.id } }"
                        >
                          {{ post.description }}
                        </router-link>
                      </p>

                      <div class="row mb-3">
                        <div class="col-sm-12 col-lg-12">
                          <!-- <router-link
                          :to="{ name: 'comments', params: { id: post.id } }"
                        >
                          <div class="col-sm-12">
                            <img
                              :src="path + post.img"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                        </router-link> -->

                          <router-link
                            :to="{ name: 'comments', params: { id: post.id } }"
                          >
                            <img
                              v-if="post.img != null"
                              :src="path + post.img"
                              alt=""
                              class="img-fluid"
                            />
                            <video
                              v-if="post.video != null"
                              :src="pathVideo + post.video"
                              style="width: 100%"
                              height="320"
                              controls
                            ></video>
                          </router-link>
                        </div>
                      </div>

                      <p>
                        <a
                          href="javascript:void(0)"
                          class="link-black text-sm mr-2"
                          v-if="!likers(post.likes, post.id)"
                          @click="like(post.id)"
                          ><i class="fas fa-thumbs-up mr-1"></i>
                          {{ post.likes.length }}
                        </a>

                        <a
                          href="javascript:void(0)"
                          class="link-black text-sm mr-2"
                          v-else
                        >
                          <i class="fas fa-thumbs-up mr-1 text-primary"></i
                          >{{ post.likes.length }}</a
                        >

                        <a
                          href="javascript:void(0)"
                          class="link-black text-sm mr-2"
                          v-if="!dislikers(post.dislikes, post.id)"
                          @click="dislike(post.id)"
                        >
                          <i class="fas fa-thumbs-down mr-1"></i>
                          {{ post.dislikes.length }}</a
                        >

                        <a
                          href="javascript:void(0)"
                          class="link-black text-sm mr-2"
                          v-else
                        >
                          <i class="fas fa-thumbs-down mr-1 text-danger"></i>
                          {{ post.dislikes.length }}
                        </a>

                        <a href="" class="link-black text-sm mr-2"
                          ><i class="fas fa-share mr-1"></i>Share
                        </a>
                        <a
                          href="javascript:void(0)"
                          @click="isdeletePost(post.id, index)"
                          class="link-black text-sm mr-2"
                          ><i class="fas fa-trash text-danger mr-1"></i>Eliminar
                        </a>
                        <span class="float-right">
                          <router-link
                            :to="{ name: 'comments', params: { id: post.id } }"
                            class="link-black text-sm pr-4"
                          >
                            <i class="fas fa-comments mr-1"></i> Comentarios
                            {{ post.comments.length }}
                          </router-link>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- <div id="about-me" class="tab-pane fade">
                  <div class="profile-personal-info">
                    <h4 class="text-primary mb-4">Informacao Pessoal</h4>
                    <div class="row mb-4">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 class="f-w-500">
                          Name <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-6 col-6">
                        <span>Mitchell C.Shay</span>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 class="f-w-500">
                          Email <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-6 col-6">
                        <span>info@example.com</span>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 class="f-w-500">
                          Availability <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-6 col-6">
                        <span>Full Time (Free Lancer)</span>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 class="f-w-500">
                          Age <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-6 col-6">
                        <span>27</span>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 class="f-w-500">
                          Location <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-6 col-6">
                        <span>Rosemont Avenue Melbourne, Florida</span>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 class="f-w-500">
                          Year Experience <span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-6 col-6">
                        <span>07 Year Experiences</span>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div id="my-config" class="tab-pane fade">
                  <div class="pt-3">
                    <div class="settings-form">
                      <h4 class="text-primary">Actualizar o Perfil</h4>
                      <form>
                        <!-- personal User data -->

                        <div class="row">
                          <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Nome Completo*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.fullName"
                        />
                      </div>
                    </div> -->
                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Username*</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="user.username"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Genero*</label>
                              <select
                                class="form-control"
                                v-model="userData.gender"
                              >
                                <option value="Gender">Genero</option>
                                <option value="Male">Masculino</option>
                                <option value="Female">Feminino</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Email*</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="userData.email"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Contacto*</label>
                              <input
                                type="text"
                                class="form-control"
                                @keypress="numberOnly"
                                pattern="/^[0-9]+$/"
                                maxlength="9"
                                v-model="userData.contact"
                              />
                            </div>
                          </div>
                          <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto Alternativo</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                          pattern="/^[0-9]+$/"
                          maxlength="9"
                          v-model="userData.alternative_contact"
                        />
                      </div>
                    </div> -->
                        </div>

                        <div class="row mb-2">
                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Senha*</label>
                              <input
                                type="password"
                                class="form-control"
                                v-model="userData.senha"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Confirmar Senha*</label>
                              <input
                                type="password"
                                class="form-control"
                                v-model="userData.confSenha"
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          v-on:click="updateData"
                          class="btn btn-primary col-lg-12 col-md-12 col-sm-12 mb-3"
                        >
                          {{
                            loading == false ? "Atualizar" : "Actualizando..."
                          }}
                        </button>
                        <span
                          >Nota:No
                          <strong class="text-white">username</strong> use um
                          nome discreto, que servira de sua identificao no
                          plataforma. <br />Ex: (laranja, xx_best e etc)
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-xxl-3 col-lg-3">
      <div class="row">
        <div class="col-lg-12">
          <!-- Profile Image -->
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <div class="text-center">
                <label for="uploadProfile">
                  <img
                    class="profile-user-img img-circle img-fluid"
                    v-if="profilePicPreview != null"
                    :src="profilePicPreview"
                    alt=""
                  />
                  <img
                    v-else
                    :src="profilePhoto"
                    class="profile-user-img img-circle img-fluid"
                  />

                  <input
                    type="file"
                    id="uploadProfile"
                    @change="addProfilePic"
                    hidden
                  />
                  <p class="text-muted text-center mt-3">
                    Clique aqui para actualizar Foto
                  </p>
                </label>
              </div>
              <h3 class="profile-username text-center">
                {{ userData.fullName }}
              </h3>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title">Sobre</h2>
            </div>
            <div class="card-body pb-0">
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex px-0 justify-content-between">
                  <strong>Genero</strong>
                  <span class="mb-0">{{ userData.gender }}</span>
                </li>
                <li class="list-group-item d-flex px-0 justify-content-between">
                  <strong>Contacto</strong>
                  <span class="mb-0">{{ userData.contact }}</span>
                </li>
                <li class="list-group-item d-flex px-0 justify-content-between">
                  <strong>Email</strong>
                  <span class="mb-0">{{ userData.email }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Eliminar o post</h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja eliminar o post?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="deleteCourse()"
              class="btn btn-danger"
            >
              {{ isDeleting == false ? "Eliminar" : "Eliminando..." }}
            </button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
    <bottomMenu />
  </div>
</template>

<script>
import store from "@/store";
import bottomMenu from "@/components/bottomMenu.vue";

import ProfileStoreModule from "./ProfileStoreModule";
import axios from "axios";
import { mapActions } from "vuex";
import PostStoreModule from "@/components/PostStoreModule";
import toastr from "toastr";
export default {
  components: {
    bottomMenu,
  },
  data() {
    return {
      firstInfoShow: true,
      secondInfoShow: false,
      stepThreeInfo: false,
      stepFourInfo: false,
      personalData: true,
      loadDistricts: true,
      schoolData: false,
      courseData: false,
      documentData: false,
      loading: false,
      deficientTypeDisabled: true,
      userData: {
        fullName: "Incognito",
        email: "",
        gender: "",
        contact: "",
        alternative_contact: "",
        senha: "",
        confSenha: "",
        username: "",
      },

      provincesData: [],
      districtData: [],
      dataPosts: [],
      imgUrl: "https://votes.vm7falaki.com/assets/images/avatar4.png",
      imgUrl2: "https://votes.vm7falaki.com/assets/images/avatar2.png",
      imgUrl3: "https://votes.vm7falaki.com/assets/images/avatar3.png",
      imgUrlPost: "https://votes.vm7falaki.com/assets/images/photo4.jpg",
      path: "https://votes.vm7falaki.com/starter/assets/upload_img/",
      ProfilePath: "https://votes.vm7falaki.com/starter/assets/upload_profile/",
      pathVideo: "https://votes.vm7falaki.com/starter/assets/upload_video/",

      // ProfilePath: "http://127.0.0.1:8000/assets/upload_profile/",
      // profilePhoto:
      // store.state.auth.user.profile_pic == null
      //   ? "https://bitwise.co.mz/assets/images/avatar4.png"
      //   : "http://127.0.0.1:8000/assets/upload_profile/" +
      //     store.state.auth.user.profile_pic,
      profilePhoto:
        store.state.auth.user.profile_pic == null
          ? "https://votes.vm7falaki.com/assets/images/avatar4.png"
          : "https://votes.vm7falaki.com/starter/assets/upload_profile/" +
            store.state.auth.user.profile_pic,
      user_id_logged: store.state.auth.user.id,
      likesPost: [],
      posters_id: [],
      user: {},
      user_id_logged: store.state.auth.user.id,
      profilePicPreview: null,
      profilePicUpload: null,
      isDelete: false,
      index: 0,
      post_id: 0,
      isDeleting: false,
    };
  },
  computed: {
    posters() {
      for (let data of this.dataPost) {
        for (let l of data.likes) {
        }
      }

      return this.posters_id;
    },
  },
  ...mapActions({
    profile: "auth/profile",
  }),

  methods: {
    likers(dataLikes, post_id) {
      if (this.user_id_logged && dataLikes) {
        for (let key of dataLikes) {
          if (
            key.users_id === this.user_id_logged &&
            key.posts_id === post_id
          ) {
            return true;
          }
        }
      }

      return false;
    },

    dislikers(dataLikes, post_id) {
      if (this.user_id_logged && dataLikes) {
        for (let key of dataLikes) {
          if (
            key.users_id === this.user_id_logged &&
            key.posts_id === post_id
          ) {
            return true;
          }
        }
      }

      return false;
    },
    async getMyPosts() {
      const res = await ProfileStoreModule.getMyPosts(store.state.auth.user.id);
      this.dataPosts = res.data.data;
      console.log(res.data.data);
    },

    async getUserData() {
      const res = await ProfileStoreModule.getUserData();
      this.userData = res.data.data[0];
      console.log(res.data.data[0]);
    },

    async getUser() {
      const res = await ProfileStoreModule.user();
      this.user = res.data;
      console.log(res.data);
    },

    ...mapActions({
      imgState: "auth/updateimgstate",
    }),

    async addProfilePic(e) {
      let data = new FormData();

      // console.log(e.target.files[0]);
      this.profilePicUpload = e.target.files[0];
      this.profilePicPreview = URL.createObjectURL(this.profilePicUpload);

      data.append("profilePic", this.profilePicUpload);
      const res = await ProfileStoreModule.createProfilePic(data);

      const resp = await ProfileStoreModule.user();
      store.dispatch("auth/updateUser", resp.data);

      //  this.LikedPost(true);
      this.imgState(true);
    },

    clearInputs() {
      this.userData.alternative_contact = "";
      this.userData.contact = "";
      this.userData.district_id = "";
      this.userData.confSenha = "";
      this.userData.email = "";
      this.userData.senha = "";
      this.userData.gender = "";
      this.userData.fullName = "";
      this.userData.province_id = "";
    },
    isdeletePost(id, i) {
      console.log("aqui", id);
      this.isDelete = true;
      this.index = i;
      this.post_id = id;
    },

    closeModal() {
      this.isDelete = false;
    },

    async deleteCourse() {
      this.isDeleting = true;
      // console.log(id_course);
      // const id = course_id.value
      const response = await ProfileStoreModule.deletePost(this.post_id);
      console.log(response);
      if (response) {
        this.dataPosts.splice(this.index, 1);
        this.isDeleting = false;
        this.isDelete = false;
        toastr.success("Post eliminado com sucesso");
      }
    },
    async updateData() {
      this.loading = true;
      let data = new FormData();

      if (((this.userData.senha == "") != this.userData.confSenha) == "") {
        this.loading = false;

        return toastr.warning("Por favor confirme a senha", "Opsssiiii");
      }

      if (
        this.userData.username == "" ||
        this.userData.gender == "" ||
        this.userData.email == "" ||
        this.userData.contact == ""
      ) {
        this.loading = false;
        return toastr.warning(
          "Por favor preecha os campos obrigatorios",
          "Opsssiiii"
        );
      }

      data.append("username", this.user.username);
      data.append("gender", this.userData.gender);
      data.append("email", this.userData.email);
      data.append("contact", this.userData.contact);
      data.append("password", this.userData.senha);
      const res = await ProfileStoreModule.updateData(data);

      if (res.status == true) {
        this.getUserData();
        const res = await ProfileStoreModule.user();
        // console.log("userupda", res.data)
        store.dispatch("auth/updateUser", res.data);
        toastr.success(res.message);
        this.loading = false;
      } else {
        toastr.error(
          "Desculpe-nos ocorreum um erro ao tentar actualizar os dados"
        );
        this.loading = false;
      }
    },

    numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    },
    formatDates(dateFormat) {
      const data = new Date(dateFormat);
      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
  },

  created() {
    if (store.state.auth.authenticated) {
      this.profilePhoto =
        store.state.auth.user.profile_pic == null
          ? "https://votes.vm7falaki.com/assets/images/avatar4.png"
          : "https://votes.vm7falaki.com/starter/assets/upload_profile/" +
            store.state.auth.user.profile_pic;
    }

    // console.log("pro", store.state.auth.user.profile_pic);
    this.getMyPosts();
    this.getUserData();
    this.getUser();
  },
};
</script>

<style scoped>
.post {
  border-bottom: 1px solid #adb5bd;
  color: #666;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.post:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.post .user-block {
  margin-bottom: 15px;
  width: 100%;
}
.post .row {
  width: 100%;
}

.img-circle {
  border-radius: 50%;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

.user-block {
  float: left;
}
.user-block img {
  float: left;
  height: 40px;
  width: 40px;
}
.user-block .comment,
.user-block .description,
.user-block .username {
  display: block;
  margin-left: 50px;
}
.user-block .username {
  font-size: 16px;
  font-weight: 600;
  margin-top: -1px;
}
.user-block .description {
  color: #6c757d;
  font-size: 13px;
  margin-top: -3px;
}

.link-black {
  color: #6c757d;
}
.link-black:focus,
.link-black:hover {
  color: #e6e8ea;
}

.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.modal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}

.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}
</style>
