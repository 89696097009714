<script setup>
import store from "@/store";
import { ref } from "vue";
import router from "@/router";

const emit = defineEmits(["profile"]);

const activeT1 = ref(true);
const activeT2 = ref(false);
const activeT3 = ref(false);
const userId = store.state.auth.user.id;

function activeTab(tab) {
  if (tab == 1) {
    activeT1.value = true;
    activeT2.value = false;
    activeT3.value = false;
  } else if (tab == 2) {
    activeT1.value = false;
    activeT2.value = true;
    activeT3.value = false;
  } else {
    activeT1.value = false;
    activeT2.value = false;
    activeT3.value = true;
  }
}

function profile() {
  router.push({
    name: "profile",
    params: { id: userId },
  });
}

function home() {
  router.push("/");
}

function notifications() {
  router.push("/notifications");
}
</script>

<template>
  <div id="BottomMenu" class="d-none d-block d-block d-md-none">
    <div class="d-flex justify-content-sm-between">
      <div
        class="col-lg-4 text-center"
        @click="activeTab(1)"
        :style="[
          { 'background-color': activeT1 == true ? '#52547c' : '#2e2f49' },
        ]"
      >
        <i class="fa fa-home"></i>
        <p @click="home">Início</p>
      </div>

      <div
        class="col-lg-4 text-center"
        @click="activeTab(2)"
        :style="[
          { 'background-color': activeT2 == true ? '#52547c' : '#2e2f49' },
        ]"
      >
        <i class="fa fa-user"></i>
        <p @click="profile">Perfil</p>
      </div>

      <div
        class="col-lg-4 text-center"
        @click="activeTab(3)"
        :style="[
          { 'background-color': activeT3 == true ? '#52547c' : '#2e2f49' },
        ]"
      >
        <i class="mdi mdi-bell-outline noti-icon"></i>
        <span class="badge badge-pill badge-danger noti-icon-badge"> 0 </span>
        <p @click="notifications">Notificações</p>
      </div>
    </div>
  </div>
</template>